"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var LOGIN = function LOGIN() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/Login.vue'));
  });
};

var Bind = function Bind() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/Bind.vue'));
  });
};

var REGISTER = function REGISTER() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/Register.vue'));
  });
};

var SETTING = function SETTING() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/Setting.vue'));
  });
};

var Secure = function Secure() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/secure/Index.vue'));
  });
};

var WechatAuthroize = function WechatAuthroize() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/WechatAuthroize.vue'));
  });
};

var Modpwd = function Modpwd() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/secure/Modpwd.vue'));
  });
};

var Modphone = function Modphone() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/secure/Modphone.vue'));
  });
};

var BindIndex = function BindIndex() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/secure/bind/Index.vue'));
  });
};

var BindWechat = function BindWechat() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/secure/bind/Wechat.vue'));
  });
};

var IdentityVerify = function IdentityVerify() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/IdentityVerify.vue'));
  });
};

var UserIndex = function UserIndex() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/Index.vue'));
  });
};

var UserAddressAdd = function UserAddressAdd() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/address/Add.vue'));
  });
};

var UserAddressEdit = function UserAddressEdit() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/address/Edit.vue'));
  });
};

var UserAddressIndex = function UserAddressIndex() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('views/user/address/Index.vue'));
  });
};

var _default = [{
  path: '/login',
  name: 'login',
  component: LOGIN,
  meta: {
    title: '登录'
  }
}, {
  path: '/bind',
  name: 'bind',
  component: Bind,
  meta: {
    title: '用户注册'
  }
}, {
  path: '/register',
  name: 'register',
  component: REGISTER,
  meta: {
    title: '注册'
  }
}, {
  path: '/my',
  name: 'UserIndex',
  component: UserIndex,
  meta: {
    title: '个人中心',
    keepAlive: false
  }
}, {
  path: '/authorize',
  name: 'WechatAuthroize',
  component: WechatAuthroize,
  meta: {
    title: '微信公众号授权'
  }
}, {
  path: '/user/identity-verify',
  name: IdentityVerify,
  component: IdentityVerify,
  meta: {
    title: '身份验证',
    auth: true
  }
}, {
  path: '/setting',
  name: 'setting',
  component: SETTING,
  meta: {
    title: '个人信息',
    auth: true
  }
}, {
  path: '/my/secure',
  name: 'secure',
  component: Secure,
  meta: {
    title: '账号安全',
    auth: true
  }
}, {
  path: '/my/secure/modpwd',
  component: Modpwd,
  meta: {
    title: '修改密码',
    auth: true
  }
}, {
  path: '/my/secure/modphone',
  component: Modphone,
  meta: {
    title: '更换手机号',
    auth: true
  }
}, {
  path: '/my/secure/bind/index',
  name: 'bindIndex',
  component: BindIndex,
  meta: {
    title: '关联账号',
    auth: true
  }
}, {
  path: '/my/secure/bind/wechat',
  name: 'bindWechat',
  component: BindWechat,
  meta: {
    title: '微信',
    auth: true
  }
}, {
  path: '/user/address/edit/:id',
  name: 'addressEdit',
  component: UserAddressEdit,
  meta: {
    title: '编辑地址',
    auth: true
  }
}, {
  path: '/user/address',
  name: 'userAddressIndex',
  component: UserAddressIndex,
  meta: {
    title: '地址列表',
    auth: true
  }
}, {
  path: '/user/address/add',
  name: 'addressAdd',
  component: UserAddressAdd,
  meta: {
    title: '添加地址',
    auth: true
  }
}, {
  path: '/user/insure',
  name: 'userInsure',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/user/Insure.vue'));
    });
  },
  meta: {
    title: '我的投保',
    auth: true
  }
}, {
  path: '/user/income/detail',
  name: 'userIncomeDetail',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/user/IncomeDetail.vue'));
    });
  },
  meta: {
    title: '收入详情',
    auth: true,
    keepAlive: false
  }
}, {
  path: '/user/income',
  name: 'userIncome',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/user/Income.vue'));
    });
  },
  meta: {
    title: '我的收入',
    auth: true,
    keepAlive: false
  }
}, {
  path: '/user/performance',
  name: 'userPerformance',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/user/performance.vue'));
    });
  },
  meta: {
    title: '我的业绩',
    auth: true,
    keepAlive: false
  }
}, {
  path: '/user/performance/detail',
  name: 'userPerformanceDetail',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/user/performanceDetail.vue'));
    });
  },
  meta: {
    title: '业绩详情',
    auth: true,
    keepAlive: false
  }
}, {
  path: '/user/company_product_pdf',
  name: 'userCompanyProductPdf',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/user/companyProductPdf.vue'));
    });
  },
  meta: {
    title: '产品费用率',
    auth: true,
    keepAlive: false
  }
}, {
  path: '/user/company_product_pdf/detail',
  name: 'userCompanyProductPdfBrowser',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/user/companyProductPdfBrowser.vue'));
    });
  },
  meta: {
    title: '产品费用率',
    auth: true,
    keepAlive: false
  }
}];
exports.default = _default;