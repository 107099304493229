"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.param2Obj = param2Obj;
exports.deepClone = deepClone;
exports.imgFit = imgFit;

require("core-js/modules/es6.regexp.constructor");

require("core-js/modules/es6.object.keys");

require("core-js/modules/web.dom.iterable");

var _typeof2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/typeof"));

require("core-js/modules/es6.regexp.replace");

require("core-js/modules/es6.regexp.split");

/**
 * @param {string} url
 * @returns {Object}
 */
function param2Obj(url) {
  var search = url.split('?')[1];

  if (!search) {
    return {};
  }

  return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"').replace(/\+/g, ' ') + '"}');
}

function deepClone(source) {
  if (!source && (0, _typeof2.default)(source) !== 'object') {
    throw new Error('error arguments', 'deepClone');
  }

  var targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach(function (keys) {
    if (source[keys] && (0, _typeof2.default)(source[keys]) === 'object') {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

function imgFit(conent) {
  var regex = new RegExp('<img', 'gi');
  return conent.replace(regex, "<img style=\"max-width: 100%; height: auto\"");
}