"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var CartIndex = function CartIndex() {
  return Promise.resolve().then(function () {
    return (0, _interopRequireWildcard2.default)(require('@/views/Cart/Index'));
  });
};

var _default = [{
  path: '/cart',
  name: 'cartIndex',
  component: CartIndex,
  meta: {
    title: '购物车',
    keepAlive: true
  }
}];
exports.default = _default;