"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _interopRequireWildcard2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard"));

var _Detail = _interopRequireDefault(require("@/views/Article/Detail"));

var _default = [{
  path: '/article',
  name: 'article',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('views/Article/List'));
    });
  },
  meta: {
    title: '文章列表',
    keepAlive: true
  }
}, {
  path: '/article/:id',
  name: 'Articledetail',
  component: _Detail.default,
  meta: {
    title: '文章详情',
    keepAlive: false
  }
}];
exports.default = _default;