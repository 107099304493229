"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("vant/es/pull-refresh/style");

var _pullRefresh = _interopRequireDefault(require("vant/es/pull-refresh"));

require("vant/es/list/style");

var _list = _interopRequireDefault(require("vant/es/list"));

require("vant/es/form/style");

var _form = _interopRequireDefault(require("vant/es/form"));

require("vant/es/uploader/style");

var _uploader = _interopRequireDefault(require("vant/es/uploader"));

require("vant/es/swipe-item/style");

var _swipeItem = _interopRequireDefault(require("vant/es/swipe-item"));

require("vant/es/swipe/style");

var _swipe = _interopRequireDefault(require("vant/es/swipe"));

require("vant/es/search/style");

var _search = _interopRequireDefault(require("vant/es/search"));

require("vant/es/card/style");

var _card = _interopRequireDefault(require("vant/es/card"));

require("vant/es/col/style");

var _col = _interopRequireDefault(require("vant/es/col"));

require("vant/es/row/style");

var _row = _interopRequireDefault(require("vant/es/row"));

require("vant/es/picker/style");

var _picker = _interopRequireDefault(require("vant/es/picker"));

require("vant/es/overlay/style");

var _overlay = _interopRequireDefault(require("vant/es/overlay"));

require("vant/es/goods-action/style");

var _goodsAction = _interopRequireDefault(require("vant/es/goods-action"));

require("vant/es/goods-action-icon/style");

var _goodsActionIcon = _interopRequireDefault(require("vant/es/goods-action-icon"));

require("vant/es/goods-action-button/style");

var _goodsActionButton = _interopRequireDefault(require("vant/es/goods-action-button"));

require("vant/es/sidebar-item/style");

var _sidebarItem = _interopRequireDefault(require("vant/es/sidebar-item"));

require("vant/es/sidebar/style");

var _sidebar = _interopRequireDefault(require("vant/es/sidebar"));

require("vant/es/sticky/style");

var _sticky = _interopRequireDefault(require("vant/es/sticky"));

require("vant/es/coupon-cell/style");

var _couponCell = _interopRequireDefault(require("vant/es/coupon-cell"));

require("vant/es/coupon-list/style");

var _couponList = _interopRequireDefault(require("vant/es/coupon-list"));

require("vant/es/datetime-picker/style");

var _datetimePicker = _interopRequireDefault(require("vant/es/datetime-picker"));

require("vant/es/button/style");

var _button = _interopRequireDefault(require("vant/es/button"));

require("vant/es/swipe-cell/style");

var _swipeCell = _interopRequireDefault(require("vant/es/swipe-cell"));

require("vant/es/popup/style");

var _popup = _interopRequireDefault(require("vant/es/popup"));

require("vant/es/area/style");

var _area = _interopRequireDefault(require("vant/es/area"));

require("vant/es/address-edit/style");

var _addressEdit = _interopRequireDefault(require("vant/es/address-edit"));

require("vant/es/address-list/style");

var _addressList = _interopRequireDefault(require("vant/es/address-list"));

require("vant/es/field/style");

var _field = _interopRequireDefault(require("vant/es/field"));

require("vant/es/switch/style");

var _switch = _interopRequireDefault(require("vant/es/switch"));

require("vant/es/radio/style");

var _radio = _interopRequireDefault(require("vant/es/radio"));

require("vant/es/radio-group/style");

var _radioGroup = _interopRequireDefault(require("vant/es/radio-group"));

require("vant/es/icon/style");

var _icon = _interopRequireDefault(require("vant/es/icon"));

require("vant/es/contact-card/style");

var _contactCard = _interopRequireDefault(require("vant/es/contact-card"));

require("vant/es/contact-list/style");

var _contactList = _interopRequireDefault(require("vant/es/contact-list"));

require("vant/es/contact-edit/style");

var _contactEdit = _interopRequireDefault(require("vant/es/contact-edit"));

require("vant/es/tag/style");

var _tag = _interopRequireDefault(require("vant/es/tag"));

require("vant/es/cell/style");

var _cell = _interopRequireDefault(require("vant/es/cell"));

require("vant/es/cell-group/style");

var _cellGroup = _interopRequireDefault(require("vant/es/cell-group"));

require("vant/es/dialog/style");

var _dialog = _interopRequireDefault(require("vant/es/dialog"));

require("vant/es/checkbox/style");

var _checkbox = _interopRequireDefault(require("vant/es/checkbox"));

require("vant/es/checkbox-group/style");

var _checkboxGroup = _interopRequireDefault(require("vant/es/checkbox-group"));

require("vant/es/submit-bar/style");

var _submitBar = _interopRequireDefault(require("vant/es/submit-bar"));

require("vant/es/nav-bar/style");

var _navBar = _interopRequireDefault(require("vant/es/nav-bar"));

require("vant/es/toast/style");

var _toast = _interopRequireDefault(require("vant/es/toast"));

require("vant/es/divider/style");

var _divider = _interopRequireDefault(require("vant/es/divider"));

require("vant/es/tab/style");

var _tab = _interopRequireDefault(require("vant/es/tab"));

require("vant/es/tabs/style");

var _tabs = _interopRequireDefault(require("vant/es/tabs"));

require("vant/es/lazyload/style");

var _lazyload = _interopRequireDefault(require("vant/es/lazyload"));

require("vant/es/count-down/style");

var _countDown = _interopRequireDefault(require("vant/es/count-down"));

require("vant/es/grid/style");

var _grid = _interopRequireDefault(require("vant/es/grid"));

require("vant/es/grid-item/style");

var _gridItem = _interopRequireDefault(require("vant/es/grid-item"));

require("vant/es/loading/style");

var _loading = _interopRequireDefault(require("vant/es/loading"));

require("vant/es/tabbar/style");

var _tabbar = _interopRequireDefault(require("vant/es/tabbar"));

require("vant/es/tabbar-item/style");

var _tabbarItem = _interopRequireDefault(require("vant/es/tabbar-item"));

var _vue = _interopRequireDefault(require("vue"));

_vue.default.use(_tabbar.default).use(_tabbarItem.default);

_vue.default.use(_loading.default);

_vue.default.use(_grid.default).use(_gridItem.default);

_vue.default.use(_countDown.default); // options 为可选参数，无则不传


_vue.default.use(_lazyload.default);

_vue.default.use(_tab.default).use(_tabs.default);

_vue.default.use(_divider.default);

_vue.default.use(_toast.default);

_vue.default.use(_navBar.default);

_vue.default.use(_submitBar.default);

_vue.default.use(_checkbox.default).use(_checkboxGroup.default);

_vue.default.use(_dialog.default);

_vue.default.use(_cell.default).use(_cellGroup.default);

_vue.default.use(_tag.default);

_vue.default.use(_contactCard.default).use(_contactList.default).use(_contactEdit.default);

_vue.default.use(_icon.default);

_vue.default.use(_radioGroup.default);

_vue.default.use(_radio.default);

_vue.default.use(_switch.default);

_vue.default.use(_field.default);

_vue.default.use(_addressList.default);

_vue.default.use(_addressEdit.default);

_vue.default.use(_area.default);

_vue.default.use(_popup.default);

_vue.default.use(_swipeCell.default);

_vue.default.use(_button.default);

_vue.default.use(_datetimePicker.default);

_vue.default.use(_couponCell.default).use(_couponList.default);

_vue.default.use(_sticky.default);

_vue.default.use(_sidebar.default);

_vue.default.use(_sidebarItem.default);

_vue.default.use(_goodsAction.default).use(_goodsActionIcon.default).use(_goodsActionButton.default);

_vue.default.use(_overlay.default);

_vue.default.use(_picker.default);

_vue.default.use(_row.default);

_vue.default.use(_col.default);

_vue.default.use(_card.default);

_vue.default.use(_search.default);

_vue.default.use(_swipe.default);

_vue.default.use(_swipeItem.default);

_vue.default.use(_uploader.default);

_vue.default.use(_form.default);

_vue.default.use(_list.default);

_vue.default.use(_pullRefresh.default);