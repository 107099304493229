"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es7.array.includes");

require("core-js/modules/es6.string.includes");

require("core-js/modules/es6.regexp.replace");

var _helpes = require("@/utils/helpes");

var _vue = _interopRequireDefault(require("vue"));

var _store = _interopRequireDefault(require("@/store"));

var _default = {
  data: function data() {
    return {};
  },
  methods: {
    wxLogin: function wxLogin(obj) {
      if ((0, _helpes.isWeixinBrowser)()) {
        window.location.replace(this.getAuthUrl(obj));
      }
    },
    getAuthUrl: function getAuthUrl(obj) {
      var redirect = obj.$route.query.redirect || '/';
      console.log('redirect', redirect);
      var uid = _store.default.getters.user && _store.default.getters.user.id ? _store.default.getters.user.id : '';

      if (uid) {
        redirect += redirect.includes('?') ? '&' : '?';
        redirect += "uid=".concat(uid);
      }

      console.log('跳转到微信的链接');
      console.log("".concat(process.env.VUE_APP_API_URL, "/oauth/wechat?redirect=") + redirect);
      return "".concat(process.env.VUE_APP_API_URL, "/oauth/wechat?redirect=") + redirect;
    }
  }
};
exports.default = _default;