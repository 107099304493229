"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = btts;

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

require("core-js/modules/es6.regexp.to-string");

var _defineProperty2 = _interopRequireDefault(require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _baidu_tts = require("@/api/baidu_tts");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function btts(param, options) {
  var url = 'http://tsn.baidu.com/text2audio';
  var opt = options || {};
  var p = param || {}; // 如果浏览器支持，可以设置autoplay，但是不能兼容所有浏览器

  var audio = document.createElement('audio');

  if (opt.autoplay) {
    console.log('autoplay true');
    audio.setAttribute('autoplay', 'autoplay');
  } else {
    console.log('autoplay false');
  } // 隐藏控制栏


  if (!opt.hidden) {
    audio.setAttribute('controls', 'controls');
  } else {
    audio.style.display = 'none';
  } // 设置音量


  if (typeof opt.volume !== 'undefined') {
    audio.volume = opt.volume;
  } // 调用onInit回调


  isFunction(opt.onInit) && opt.onInit(audio); // 默认超时时间60秒

  var DEFAULT_TIMEOUT = 60000;
  var timeout = opt.timeout || DEFAULT_TIMEOUT; // 创建XMLHttpRequest对象

  var xhr = new XMLHttpRequest();
  xhr.open('POST', url); // 创建form参数

  var data = _objectSpread({}, param);

  var token; // 赋值预定义参数

  (0, _baidu_tts.getToken)().then(function (res) {
    token = res.access_token;
    data.tok = token;
    data.cuid = token;
    data.ctp = 1;
    data.lan = data.lan || 'zh';
    data.aue = data.aue || 3; // 序列化参数列表

    var fd = [];

    for (var k in data) {
      fd.push(k + '=' + encodeURIComponent(data[k]));
    } // 用来处理blob数据


    var frd = new FileReader();
    xhr.responseType = 'blob';
    xhr.send(fd.join('&')); // 用timeout可以更兼容的处理兼容超时

    var timer = setTimeout(function () {
      xhr.abort();
      isFunction(opt.onTimeout) && opt.onTimeout();
    }, timeout);

    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        clearTimeout(timer);

        if (xhr.status == 200) {
          if (xhr.response.type === 'audio/mp3') {
            audio.setAttribute('src', URL.createObjectURL(xhr.response)); // autoDestory设置则播放完后移除audio的dom对象

            if (opt.autoDestory) {
              audio.onended = isFunction(opt.onEnded) && opt.onEnded(audio);

              audio.onended = function () {
                isFunction(opt.oneended) && opt.oneended(audio);
              };
            }

            isFunction(opt.onSuccess) && opt.onSuccess(audio);
          } // 用来处理错误


          if (xhr.response.type === 'application/json') {
            console.log('用来处理错误');
            console.log(frd);

            frd.onload = function () {
              var text = frd.result;
              isFunction(opt.onError) && opt.onError(text);
            };

            frd.readAsText(xhr.response);
          }
        }
      }
    };
  }).catch(function (error) {
    console.log('catch error');
    console.log(error);
  }); // 判断是否是函数

  function isFunction(obj) {
    if (Object.prototype.toString.call(obj) === '[object Function]') {
      return true;
    }

    return false;
  }
}