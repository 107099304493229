"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/hsgx/anxinshouhu_h5/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vue = _interopRequireDefault(require("vue"));

var _vuex = _interopRequireDefault(require("vuex"));

var _user = _interopRequireDefault(require("./modules/user"));

var _test = _interopRequireDefault(require("./modules/test"));

var _getters = _interopRequireDefault(require("./getters"));

var _vuexLoading = _interopRequireDefault(require("utils/vuex-loading"));

_vue.default.use(_vuex.default);

var store = new _vuex.default.Store({
  plugins: [(0, _vuexLoading.default)()],
  mutations: {
    // 更新页面切换方向
    updateDirection: function updateDirection(state, direction) {
      state.direction = direction;
    }
  },
  modules: {
    user: _user.default,
    test: _test.default
  },
  getters: _getters.default
});
var _default = store;
exports.default = _default;